import Button from './index'

const PrimaryButton = ({ children, className, disabled, ...props }) => {
    return (
        <Button
            disabled={disabled}
            className={`w-full rounded-xl p-3 ${className} ${
                disabled
                    ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                    : 'bg-blue-kiwi text-white'
            }`}
            {...props}>
            {children}
        </Button>
    )
}

export default PrimaryButton
