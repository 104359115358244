import React, { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'

import VeritecTemplate from 'templates/Veritec'
import { UsersAPI } from 'api'

export default function RequestLoanVeritec() {
    const navigate = useNavigate()

    const { state: requestState, saveState: saveRequestState } =
        useContext(RequestLoanContext)
    const onEligible = async () => {
        await saveCreditReport()
        navigate('/request-loan/income')
    }

    const saveCreditReport = async () => {
        if (requestState.ssn.completed === false) {
            await UsersAPI.creditReport({
                source: 'loan_ask'
            })
            await saveRequestState({
                ...requestState,
                ssn: {
                    completed: true
                },
                veritec: {
                    completed: true
                }
            })
        }
    }

    return (
        <Fragment>
            <VeritecTemplate onEligible={onEligible} />
        </Fragment>
    )
}
