import React, {createContext, useEffect} from 'react';
import {trackEvent} from 'utils/dmp_kiwi';
export const TrackingContext = createContext();

const TrackingProvider = ({children}) => {
    const pathname = window.location.href;

    const sendPageViewEvent = async path => {
        await trackEvent({
            event_id: `d56d3aa6-aa06-4015-be43-44b2e55270d2`,
            pathname: path,
            event_type: 'page_view',
        });
    };

    useEffect(() => {
        sendPageViewEvent(pathname);
    }, [location]);

    return (
        <TrackingContext.Provider value={{}}>
            {children}
        </TrackingContext.Provider>
    );
};

export default TrackingProvider;
