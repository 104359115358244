import http from 'utils/http'

const evaluetaRenewal = async () => {
    const response = await http.post('/applications/evaluate-renewal')

    return response
}

export default {
    evaluetaRenewal,
};
