import React from 'react';
import {Routes, Route} from 'react-router-dom';

/**
 * Contexts
 */
import {AuthProvider} from 'context/AuthContext';
import {UserProvider} from 'context/UserContext';

/**
 * Middlewares
 */
import {AuthMiddleware} from './middlewares/AuthMiddleware';
import {UnauthMiddleware} from './middlewares/UnauthMiddleware';
import {PixelMiddleware} from './middlewares/PixelMiddleware';

import NotFound from 'views/mobile/NotFound';
import AuthRoutes from './AuthRoutes';
import UnauthRoutes from './UnauthRoutes';
import CreditLimitRoutes from './CreditLimitRoutes';
import RequestLoanRoutes from './RequestLoanRoutes';
import ApprovedLoanRoutes from './ApprovedLoanRoutes';
import ActiveLoanRoutes from './ActiveLoanRoutes';
import CreditBuilderRoutes from './CreditBuilderRoutes';
import CreditMonitoringRoutes from './CreditMonitoringRoutes';
import RouletteRoutes from './RouletteRoutes';

import CreditMonitoringEmbedRegister from 'views/mobile/CreditMonitoring/EmbedRegister';
import CreditMonitoringEmbedReport from 'views/mobile/CreditMonitoring/EmbedReport';
import Maintenance from 'views/mobile/Maintenance';

import ChangePhoneLayout from 'views/mobile/ChangePhone';
import ChangePhone from 'views/mobile/ChangePhone/Form';
import ChangePhoneSentEmail from 'views/mobile/ChangePhone/SentEmail';
import ChangePhoneNew from 'views/mobile/ChangePhone/New';
import ChangePhoneError from 'views/mobile/ChangePhone/Error';
import ChangePhoneVerify from 'views/mobile/ChangePhone/Verify';
import ChangePhoneSuccess from 'views/mobile/ChangePhone/Success';
import CombineAccountsSuccess from 'views/mobile/CombineAccounts/Success';

const AppRoutes = () => (
    <AuthProvider>
        <UserProvider>
            <Routes>
                <Route element={<PixelMiddleware />}>
                    <Route path="*" element={<NotFound />} />
                    <Route exact path="maintenance" element={<Maintenance />} />
                    <Route path="/">
                        <Route element={<UnauthMiddleware />}>
                            {UnauthRoutes}
                        </Route>
                        <Route element={<AuthMiddleware />}>
                            {AuthRoutes}
                            {CreditLimitRoutes}
                            {RequestLoanRoutes}
                            {ApprovedLoanRoutes}
                            {ActiveLoanRoutes}
                            {CreditBuilderRoutes}
                            {CreditMonitoringRoutes}
                            {RouletteRoutes}
                        </Route>

                        <Route
                            path="array-register"
                            element={<CreditMonitoringEmbedRegister />}></Route>
                        <Route
                            path="array-report"
                            element={<CreditMonitoringEmbedReport />}></Route>

                        <Route
                            exact
                            path="change-phone"
                            element={<ChangePhoneLayout />}>
                            <Route exact path="" element={<ChangePhone />} />
                            <Route
                                exact
                                path="sent-email"
                                element={<ChangePhoneSentEmail />}
                            />
                            <Route
                                exact
                                element={<ChangePhoneNew />}
                                path="new-phone/:tokenNewPhone"
                            />
                            <Route
                                exact
                                path="error"
                                element={<ChangePhoneError />}
                            />
                            <Route
                                exact
                                path="verify"
                                element={<ChangePhoneVerify />}
                            />
                            <Route
                                exact
                                path="success"
                                element={<ChangePhoneSuccess />}
                            />
                        </Route>

                        <Route
                            exact
                            path="/combine-accounts/success/:token"
                            element={<CombineAccountsSuccess />}
                        />
                    </Route>
                </Route>
            </Routes>
        </UserProvider>
    </AuthProvider>
);

export default AppRoutes;
