import * as Yup from 'yup';
import formatter from 'phone-formatter';
import PatternFormat from 'react-number-format';
import React, {useState, Fragment, useContext, useRef, useReducer} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import {UsersAPI} from 'api';
import {UserContext} from 'context/UserContext';

import Button from 'components/Button';
import LoadingModal from 'components/Modals/Loading';
import CombineAccountsModal from 'components/Modals/CombineAccounts';
import {CombineAccountsContext} from 'context/CombineAccounts';

export default function SSNTemplate({
    context,
    onContinue,
    warningSlot,
    termsSlot,
}) {
    const CombineAccountsRef = useRef();
    const form = useRef();

    const {state: currentState} = useContext(context);
    const {setState: combineSetState} = useContext(CombineAccountsContext);
    const {
        state: userState,
        setState: setUserState,
        userVerify,
        updateUser,
    } = useContext(UserContext);
    const [agreeTerms, setAgreeTerms] = useState(false);

    const {getData: getDataFingerprint} = useVisitorData(
        {extendedResult: true},
        {immediate: true},
    );

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'ssn_success',
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [ssnFormat, setSSNFormat] = useReducer(
        (state, updates) => ({...state, ...updates}),
        {
            value: '#########',
            confirmValue: '#########',
        },
    );

    const onToggleSSN = (event, type) => {
        let ssn;

        const label = event.currentTarget;
        const input = label.parentNode.firstChild;
        const value = form.current.values[type] ?? '';

        if (label.innerHTML === 'Ver SSN') {
            setSSNFormat({
                [type]: '###-##-####',
            });
            ssn = formatter.format(value, 'NNN-NN-NNNN').replaceAll('N', '');
            input.type = 'text';
            label.innerHTML = 'Ocultar SSN';
        } else {
            setSSNFormat({
                [type]: '#########',
            });
            ssn = value.replaceAll('-', '');
            input.type = 'password';
            label.innerHTML = 'Ver SSN';
        }

        form.current.setFieldValue(type, ssn);
        form.current.setFieldTouched(type, false, false);
    };

    const checkMergeAccount = async ssn => {
        const {
            data: {
                data: {coincidence, last4PhoneNumber, email},
            },
        } = await UsersAPI.checkMergeAccount(ssn);

        console.log(coincidence);
        if (coincidence) {
            combineSetState({
                last4PhoneNumber,
                email,
                ssn,
            });

            CombineAccountsRef.current.openModal();
        }
    };

    const onSubmit = async ssnValues => {
        if (loading === true || agreeTerms === false) {
            return;
        }

        try {
            setLoading(true);
            setShowLoader(true);
            /**
             * Si el usuario ya tiene un SSN registrado...
             */
            if (userState.user.identification_number) {
                return onContinue(userState.user.identification_number);
            }

            const ssn = {
                ...ssnValues,
            };

            ssn.value = ssn.value.replaceAll('-', '');
            ssn.confirmValue = ssn.confirmValue.replaceAll('-', '');

            const identification_number = ssn.value;

            await checkMergeAccount(identification_number);
            await userVerify({identification_number});
            await updateUser({identification_number});

            await fingerprintSetData();
            setUserState({
                ...userState,
                user: {
                    ...userState.user,
                    identification_number: identification_number.replace(
                        /^\d{5}/,
                        '*****',
                    ),
                },
            });

            onContinue(identification_number);
        } catch (error) {
            const {response} = error;

            if (response && response.data) {
                const {message} = response.data;
                if (
                    response.data.code === 400 ||
                    ['SSN invalid', 'Data', 'Error', 'WARNING'].includes(
                        response.data?.data?.responsedescription,
                    )
                ) {
                    form.current.setFieldError(
                        'value',
                        'Problemas al guardar tu SSN, por favor contacta a support@kiwicredito.com',
                    );
                }

                if (message.includes('SSN is already registered')) {
                    form.current.setFieldError(
                        'value',
                        'Problemas al guardar tu SSN, por favor contacta a support@kiwicredito.com',
                    );
                }
            }
        } finally {
            setLoading(false);
            setShowLoader(false);
        }
    };

    const validationSchema = Yup.object().shape({
        value: Yup.lazy(() => {
            if (userState.user.identification_number) {
                return Yup.string();
            }

            return Yup.string()
                .required('El campo es requerido')
                .test(
                    'ssnLength',
                    'Escribe todo los dígitos de tu SNN',
                    val => {
                        if (val === undefined) {
                            return false;
                        }
                        if (
                            ssnFormat.value === '###-##-####' &&
                            val.length === 11
                        ) {
                            return true;
                        }
                        if (
                            ssnFormat.value === '#########' &&
                            val.length === 9
                        ) {
                            return true;
                        }
                    },
                );
        }),
        confirmValue: Yup.lazy(() => {
            if (userState.user.identification_number) {
                return Yup.string();
            }

            return Yup.string()
                .required('El campo es requerido')
                .test(
                    'ssnLength',
                    'Escribe todo los dígitos de tu SNN',
                    val => {
                        if (val === undefined) {
                            return false;
                        }
                        if (
                            ssnFormat.confirmValue === '###-##-####' &&
                            val.length === 11
                        ) {
                            return true;
                        }
                        if (
                            ssnFormat.confirmValue === '#########' &&
                            val.length === 9
                        ) {
                            return true;
                        }
                    },
                )
                .test(
                    'confirmValues',
                    'Los dígitos de tu SNN no coinciden',
                    val => {
                        if (val === undefined) {
                            return false;
                        }
                        const ssn = form.current.values.value.replaceAll(
                            '-',
                            '',
                        );
                        const confirmSSN = val.replaceAll('-', '');
                        if (ssn === confirmSSN) {
                            return true;
                        }
                    },
                );
        }),
    });

    return (
        <Formik
            initialValues={currentState.ssn}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={form}>
            {({errors, touched}) => (
                <Fragment>
                    <LoadingModal isVisible={showLoader} />
                    <CombineAccountsModal ref={CombineAccountsRef} />
                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Ingresa tu Número de Seguro Social (SSN)
                        </h1>
                        <p>
                            Requerimos tu SSN para verificar tu identidad y
                            prevenir el fraude.
                        </p>

                        <div className="mt-8 mb-auto">
                            <div>
                                <label
                                    htmlFor="value"
                                    className="inline-block text-sm mb-2">
                                    Número de seguro social
                                </label>
                                {userState.user.identification_number ? (
                                    <Fragment>
                                        <Field
                                            value={
                                                userState.user
                                                    .identification_number
                                            }
                                            className={`focus:border-blue-kiwi placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi`}
                                            disabled
                                        />
                                        <ErrorMessage
                                            name="value"
                                            component="small"
                                            className="text-red-kiwi inline-block text-xs w-full mt-2"
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className="relative">
                                            <Field name="value" id="value">
                                                {({field, ...props}) => (
                                                    <PatternFormat
                                                        {...field}
                                                        {...props}
                                                        format={ssnFormat.value}
                                                        mask=""
                                                        type="password"
                                                        className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                            errors.value &&
                                                            touched.value
                                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                        }`}
                                                        autoComplete="off"
                                                    />
                                                )}
                                            </Field>
                                            <small
                                                className={`cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 ${
                                                    errors.value &&
                                                    touched.value
                                                        ? 'text-red-kiwi'
                                                        : 'text-blue-kiwi'
                                                }`}
                                                onClick={e =>
                                                    onToggleSSN(e, 'value')
                                                }>
                                                Ver SSN
                                            </small>
                                        </div>
                                        <ErrorMessage
                                            name="value"
                                            component="small"
                                            className="text-red-kiwi inline-block text-xs w-full mt-2"
                                        />
                                    </Fragment>
                                )}
                            </div>

                            {!userState.user.identification_number && (
                                <div className="mt-6">
                                    <label
                                        htmlFor="confirmValue"
                                        className="inline-block text-sm mb-2">
                                        Confirmar Número de Seguro Social
                                    </label>
                                    <div className="relative">
                                        <Field
                                            name="confirmValue"
                                            id="confirmValue">
                                            {({field, ...props}) => (
                                                <PatternFormat
                                                    {...field}
                                                    {...props}
                                                    format={
                                                        ssnFormat.confirmValue
                                                    }
                                                    mask=""
                                                    type="password"
                                                    className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                        errors.confirmValue &&
                                                        touched.confirmValue
                                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                    }`}
                                                    autoComplete="off"
                                                />
                                            )}
                                        </Field>
                                        <small
                                            className={`cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 ${
                                                errors.confirmValue &&
                                                touched.confirmValue
                                                    ? 'text-red-kiwi'
                                                    : 'text-blue-kiwi'
                                            }`}
                                            onClick={e =>
                                                onToggleSSN(e, 'confirmValue')
                                            }>
                                            Ver SSN
                                        </small>
                                    </div>
                                    <ErrorMessage
                                        name="confirmValue"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            )}

                            {warningSlot}
                        </div>

                        <div className="flex items-start gap-4 text-xs text-gray-500-kiwi mt-10">
                            <label
                                className="relative flex items-center"
                                htmlFor="agreeTerms">
                                <input
                                    type="checkbox"
                                    className="peer cursor-pointer w-6 h-6 rounded-md border border-gray-400-kiwi bg-gray-100-kiwi checked:bg-blue-kiwi checked:hover:bg-blue-kiwi checked:focus:bg-blue-kiwi"
                                    id="agreeTerms"
                                    onChange={e =>
                                        setAgreeTerms(e.currentTarget.checked)
                                    }
                                />
                            </label>
                            {termsSlot}
                        </div>

                        <Button
                            className={`rounded-xl p-3 mt-10 ${
                                agreeTerms
                                    ? 'bg-blue-kiwi text-white'
                                    : 'bg-gray-200-kiwi text-gray-500-kiwi'
                            }`}
                            loading={loading}
                            disabled={!agreeTerms}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
