import * as Yup from 'yup';
import PatternFormat from 'react-number-format';
import React, {useState, useEffect, Fragment, useContext, useRef} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {UserContext} from 'context/UserContext';
import Button from 'components/Button';
import SelectCity from 'components/Modals/SelectCity';
import MapsAutocompleteField from 'components/MapsAutocompleteField';
import STATE_CONSTANTS from 'constants/states';
import {UsersAPI} from 'api';

export default function AddressTemplate({context, onContinue}) {
    const form = useRef();

    const {state: currentState, saveState: saveCurrentState} =
        useContext(context);
    const {
        state: userState,
        setState: setUserState,
        updateUser,
    } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [cityVisible, setCityVisible] = useState(false);

    useEffect(() => {
        const {address: value, suite, state, city, zipcode} = userState.user;

        const initialValues = {
            value: value || '',
            suite: suite || '',
            state: state || 'Puerto Rico',
            city: city || '',
            zipcode: zipcode || '',
            completed: false,
        };

        form.current.setValues(initialValues);
    }, []);

    const {cities} = STATE_CONSTANTS(userState.user.state).address;

    const onSubmit = async address => {
        try {
            if (loading === true) {
                return;
            }

            setLoading(true);

            delete address.completed;

            const payload = {
                ...address,
                address: address.value,
            };

            delete payload.value;

            await updateUser(payload);
            try {
                await UsersAPI.verifyAddress(payload);
            } catch (error) {
                console.log(error);
            }

            setUserState({
                ...userState,
                user: {
                    ...userState.user,
                    ...payload,
                },
            });

            await saveCurrentState({
                ...currentState,
                address: {
                    ...address,
                    completed: true,
                },
            });

            onContinue(address);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        value: Yup.string().trim().required('Este campo es requerido'),
        suite: Yup.string().trim(),
        city: Yup.string().required('Este campo es requerido'),
        state: Yup.string().required('Este campo es requerido'),
        zipcode: Yup.string()
            .required('Este campo es requerido')
            .test(
                'minZip',
                'El código debe ser de 5 dígitos',
                val => val && val.length === 5,
            ),
    });

    return (
        <Formik
            initialValues={currentState.address}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={form}>
            {({errors, touched}) => (
                <Fragment>
                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            ¿Dónde vives?
                        </h1>
                        <p>
                            Ingresa tu dirección residencial, puede ser tu
                            departamento o casa.
                        </p>

                        <div className="flex flex-col gap-6 mb-10 mt-8">
                            <div>
                                <label
                                    htmlFor="value"
                                    className="inline-block text-sm mb-2">
                                    Dirección
                                </label>

                                <Field id="value" name="value">
                                    {({field, form: {touched, errors}}) => (
                                        <MapsAutocompleteField
                                            field={field}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="value"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="suite"
                                    className="inline-block text-sm mb-2">
                                    Apt/Suite{' '}
                                    <span className="text-gray-400-kiwi">
                                        (opcional)
                                    </span>
                                </label>
                                <Field
                                    id="suite"
                                    name="suite"
                                    className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                        errors.suite && touched.suite
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                    }`}
                                />
                                <ErrorMessage
                                    name="suite"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div className="flex gap-6">
                                <div className="w-1/2">
                                    <label
                                        htmlFor="state"
                                        className="inline-block text-sm mb-2">
                                        Estado
                                    </label>
                                    <Field
                                        id="state"
                                        name="state"
                                        className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                            errors.state && touched.state
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                        }`}
                                        disabled
                                    />
                                    <ErrorMessage
                                        name="state"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>

                                <div className="w-1/2">
                                    <label
                                        htmlFor="city"
                                        className="inline-block text-sm mb-2">
                                        Ciudad
                                    </label>
                                    <Field name="city">
                                        {({field, form}) => (
                                            <div>
                                                <div
                                                    className={`h-[42px] focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                        errors.city &&
                                                        touched.city
                                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                    }`}
                                                    disabled
                                                    onClick={() =>
                                                        setCityVisible(true)
                                                    }>
                                                    {field.value}
                                                </div>
                                                <SelectCity
                                                    data={cities}
                                                    selectedCity={field.value}
                                                    isVisible={cityVisible}
                                                    closeModal={() => {
                                                        setCityVisible(false);
                                                    }}
                                                    selectCity={city =>
                                                        form.setFieldValue(
                                                            field.name,
                                                            city,
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="city"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="zipcode"
                                    className="inline-block text-sm mb-2">
                                    Código postal
                                </label>
                                <Field name="zipcode" id="zipcode">
                                    {({field, ...props}) => (
                                        <PatternFormat
                                            {...field}
                                            {...props}
                                            format="#####"
                                            mask=""
                                            placeholder="00000"
                                            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                errors.zipcode &&
                                                touched.zipcode
                                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                            }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="zipcode"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
