import axios from 'axios';

const TIKTOK_API_URL = process.env.REACT_APP_TIKTOK_API_URL;
const ACCESS_TOKEN = process.env.REACT_APP_TIKTOK_ACCESS_TOKEN;

export const sendTikTokEvent = async eventData => {
    await axios.post(TIKTOK_API_URL, eventData, {
        headers: {
            'Access-Token': ACCESS_TOKEN,
            'Content-Type': 'application/json',
        },
    });
};

export default {
    sendTikTokEvent,
};
