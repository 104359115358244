import delay from 'delay';
import React, {useState, useEffect, Fragment, useContext} from 'react';
import {Formik, Form, Field} from 'formik';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';

import LoadingModal from 'components/Modals/Loading';
import ProgressBar from 'components/ProgressBar';
import Advisement from 'components/Advisement';
import {UserContext} from 'context/UserContext';
import MilitaryActiveModal from 'components/Modals/MilitaryActive';

export default function RequestLoanMilitary() {
    const navigate = useNavigate();

    const {state: userState} = useContext(UserContext);
    const {state: requestState, saveState} = useContext(RequestLoanContext);
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [militarActiveModal, setMilitarActiveModal] = useState(false);

    useEffect(() => {
        if (requestState.employment.completed === false) {
            return navigate('/request-loan/employment');
        }
    }, []);

    const onSelectOption = async value => {
        try {
            setLoading(true);
            setShowLoader(true);

            await delay(300);
            saveState({
                ...requestState,
                military: {
                    value: value,
                    completed: true,
                },
            });

            navigate('/request-loan/employer');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setShowLoader(false);
        }
    };

    const onContinue = async $event => {
        if (loading === true) {
            return;
        }

        if ($event.target.value === 'Soy miembro activo') {
            setMilitarActiveModal(true);
            return;
        }

        await onSelectOption($event.target.value);
    };

    const onBackward = () => {
        navigate('/request-loan/employment');
    };

    const MILITARY_LIST = ['Soy miembro activo', 'No soy miembro activo'];

    const initialState = {
        value: '',
    };

    return (
        <Formik initialValues={initialState}>
            {() => (
                <Fragment>
                    <LoadingModal isVisible={showLoader} />

                    <ProgressBar progress="6/12" onBackward={onBackward} />

                    <MilitaryActiveModal
                        isVisible={militarActiveModal}
                        closeModal={() => setMilitarActiveModal(false)}
                        onSelect={onSelectOption}
                    />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                        Información sobre servicio militar activo
                        </h1>

                        <div className="mt-4">
                            La ley federal ofrece protecciones importantes a los
                            miembros en servicio activo de las Fuerzas Armadas y
                            a sus dependientes. Para asegurar que estas
                            protecciones se apliquen a los solicitantes
                            elegibles, selecciona una opción:
                        </div>

                        <div className="flex flex-col gap-6 mb-10 mt-8">
                            {MILITARY_LIST.map(item => (
                                <div className="relative" key={item}>
                                    <Field
                                        className="peer hidden"
                                        type="radio"
                                        id={item}
                                        name="value"
                                        value={item}
                                        onClick={onContinue}
                                    />
                                    <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                                    <label
                                        htmlFor={item}
                                        className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                        {item}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <Advisement className="mt-auto">
                            <strong>Advertencia</strong>: Hacer una declaración
                            falsa en una solicitud de crédito constituye un
                            delito.
                        </Advisement>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
