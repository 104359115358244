import React, {Fragment, useContext} from 'react';
import {AuthContext} from 'context/AuthContext';

import SuccessOnboardingSVG from 'assets/onboarding_v3/phone_success.svg';
import Button from 'components/Button';
import {useNavigate} from 'react-router-dom';

export default function ChangePhoneSuccess() {
    const navigate = useNavigate();
    const {state: authState} = useContext(AuthContext);

    const onBackward = () => {
        if (authState.logged) {
            return navigate('/personal-information');
        }

        navigate('/login');
    };

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Felicitaciones!
                </h1>
                <p>
                    El cambio de tu número de celular se ha realizado con éxito
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onBackward}>
                Explorar
            </Button>
        </Fragment>
    );
}
