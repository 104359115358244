import * as Yup from 'yup';
import mixpanel from 'utils/mixpanel';
import NumberFormat from 'react-number-format';
import React, {useState, useEffect, Fragment, useContext} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';

import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import {UserContext} from 'context/UserContext';

export default function RequestLoanIncome() {
    const navigate = useNavigate();

    const {state: userState} = useContext(UserContext);
    const {state: requestState, saveState} = useContext(RequestLoanContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_income',
            dl_user_id: userState.user.id,
        });

        if (requestState.ssn.completed === false) {
            return navigate('/request-loan/ssn');
        }
    }, []);

    const onContinue = income => {
        if (loading === true) {
            return;
        }

        try {
            setLoading(true);

            saveState({
                ...requestState,
                income: {
                    ...income,
                    completed: true,
                },
            });

            mixpanel.track('Month Income Survey', {
                Income: income.value,
            });

            const incomeValue = Math.floor(
                Number(income.value.replaceAll(',', '').replace('$ ', '')),
            );
            dataLayer.push({
                event: 'new_application',
                eventCategory: 'loan_process',
                eventAction: 'loan_process_job_tittle',
                dl_user_id: userState.user.id,
                dl_income: incomeValue,
            });

            navigate('/request-loan/employment');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onBackward = () => {
        navigate('/request-loan/ssn');
    };

    const validationSchema = Yup.object().shape({
        value: Yup.string()
            .required('Este campo es requerido')
            .test('min-amount', 'El ingreso debe ser mayor a $150', money => {
                if (money) {
                    let amount = Number(
                        money.replaceAll(',', '').replace('$ ', ''),
                    );

                    if (amount >= 150) {
                        return true;
                    }
                }
            })
            .test(
                'max-amount',
                'El ingreso máximo debe ser menor a $100,000',
                money => {
                    if (money) {
                        let amount = Number(
                            money.replaceAll(',', '').replace('$ ', ''),
                        );

                        if (amount < 100000) {
                            return true;
                        }
                    }
                },
            ),
    });

    return (
        <Formik
            initialValues={requestState.income}
            validationSchema={validationSchema}
            onSubmit={onContinue}>
            {({errors, touched}) => (
                <Fragment>
                    <ProgressBar progress="4/12" onBackward={onBackward} />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                            ¿Cuál es tu ingreso mensual?
                        </h1>

                        <div className="my-10">
                            <div className="mb-6">
                                <label
                                    htmlFor="value"
                                    className="inline-block text-sm mb-2">
                                    Ingresa el monto*
                                </label>
                                <div className="relative">
                                    <div
                                        className={`absolute top-1/2 right-4 -translate-y-1/2 ${
                                            errors.value && touched.value
                                                ? 'text-red-kiwi'
                                                : ''
                                        }`}>
                                        / mes
                                    </div>
                                    <Field name="value" id="value">
                                        {({field, ...props}) => (
                                            <NumberFormat
                                                {...field}
                                                {...props}
                                                id="value"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                allowNegative={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                placeholder="$ 0.00"
                                                autoComplete="off"
                                                className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                    errors.value &&
                                                    touched.value
                                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name="value"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>
                        <p className="text-xs text-gray-500-kiwi mt-auto">
                            *Por favor, indica todos los ingresos que percibes,
                            incluyendo salarios, jubilación, inversiones y
                            alquileres. Si tienes ingresos o beneficios no
                            sujetos a impuestos, considerarlos aumentando en un
                            25%
                        </p>
                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
