import {UserContext} from 'context/UserContext';
import React, {useContext, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {getAuthContextData} from 'utils/getAuthContext';

export default function CreateAccount() {
    const {state: userState} = useContext(UserContext);

    useEffect(() => {
        const authContext = getAuthContextData();
    }, []);
    return (
        <div className="flex flex-col w-full min-h-full px-6 pb-10 pt-24">
            <Outlet />
        </div>
    );
}
