import LinkButton from 'components/Button/Link';
import PrimaryButton from 'components/Button/Primary';
import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import BaseModal from './Modal';
import UsersWarningSVG from 'assets/onboarding_v3/users_warning.svg';
import {useNavigate} from 'react-router-dom';
import {UserContext} from 'context/UserContext';
import {CombineAccountsContext} from 'context/CombineAccounts';

const CombineAccountsModal = forwardRef(({}, ref) => {
    const navigate = useNavigate();
    const BaseModalRef = useRef();
    const {state: combineState} = useContext(CombineAccountsContext);
    const {state: userState} = useContext(UserContext);

    const onCloseModal = () => {
        BaseModalRef.current.closeModal();
    };

    const onUpdateAccount = () => {
        navigate('/combine-accounts', {
            state: {
                backwardRoute: '/request-loan/ssn',
            },
        });
    };

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current,
    }));

    return (
        <BaseModal
            title="Detectamos que ya tienes una cuenta creada"
            ref={BaseModalRef}
            showDetails={false}>
            <img src={UsersWarningSVG} className="mx-auto mb-10" />

            <div className="mb-10 text-center">
                <h1 className="text-xl font-semibold text-regal-blue-950-kiwi mb-6">
                    Detectamos que ya tienes una cuenta creada
                </h1>
                <p>
                    Hemos encontrado que ya tienes una cuenta asociada con el
                    número de celular terminado en{' '}
                    <span className="font-semibold">
                        {combineState.last4PhoneNumber?.slice(-4)}
                    </span>
                    . Si deseas continuar, actualizaremos esa cuenta con tu
                    nuevo número de celular{' '}
                    <span className="font-semibold">
                        {userState.user.phone_number}
                    </span>
                </p>
            </div>

            <div className="flex flex-col gap-6 items-center">
                <PrimaryButton onClick={onUpdateAccount}>
                    Actualizar cuenta
                </PrimaryButton>
                <LinkButton onClick={onCloseModal}>Cancelar</LinkButton>
            </div>
        </BaseModal>
    );
});

export default CombineAccountsModal;
