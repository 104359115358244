const LinkButton = ({ children, className, ...props }) => {
    return (
        <div
            className={`text-blue-kiwi inline-block ${className}`}
            {...props}>
            {children}
        </div>
    )
}

export default LinkButton
