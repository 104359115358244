import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from 'context/AuthContext';
import {UserContext} from 'context/UserContext';
import mixpanel from 'utils/mixpanel';
import PrivacyTermsModal from 'components/Modals/PrivacyTerms';
import PaymentTermsModal from 'components/Modals/PaymentTerms';

import Subtract from 'assets/subtractDynamic';

import {LogoutIcon} from '@heroicons/react/outline';
import Menu from 'templates/Menu';
import {
    Document2Icon,
    GiftIcon,
    MailSendIcon,
    SafetyCertificateIcon,
    User3Icon,
} from 'assets/icons_v3/fonts';
import MenuLink from 'components/MenuLink';

export default function Settings() {
    const navigate = useNavigate();

    const {resetState: resetAuthState} = useContext(AuthContext);
    const {state: userState, resetState: resetUserState} =
        useContext(UserContext);
    const [privacyTermsVisible, setPrivacyTermsVisible] = useState(false);
    const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);

    const MENU_LIST = [
        [
            {
                icon: <User3Icon />,
                title: 'Información personal',
                action: function () {
                    navigate('/personal-information');
                },
            },
            {
                icon: <GiftIcon />,
                title: 'Referir a un amigo',
                action: function () {
                    mixpanel.track('Referrals Page', {
                        Type: 'Profile',
                    });
                    navigate('/referrals');
                },
            },
            {
                icon: <MailSendIcon />,
                title: 'Contáctanos por correo',
                description: 'support@kiwicredito.com',
                action: function () {
                    const mail = document.createElement('a');
                    mail.href = 'mailto:support@kiwicredito.com';
                    mail.click();
                    mail.remove();
                },
            },
        ],
        [
            {
                icon: <SafetyCertificateIcon />,
                title: 'Política de privacidad',
                action: function () {
                    setPrivacyTermsVisible(true);
                },
            },
            {
                icon: <Document2Icon />,
                title: 'Términos y condiciones',
                action: function () {
                    setTermsConditionsVisible(true);
                },
            },
        ],
        [
            {
                icon: <Document2Icon />,
                title: 'Documentos y contratos',
                action: function () {
                    navigate('/documents');
                },
            },
        ],
    ];

    const onLogout = async () => {
        resetUserState();
        resetAuthState();
    };

    return (
        <div className="w-full min-h-full flex flex-col px-6 pt-24 pb-[136px]">
            <PrivacyTermsModal
                isVisible={privacyTermsVisible}
                closeModal={() => setPrivacyTermsVisible(false)}
            />
            <PaymentTermsModal
                isVisible={termsConditionsVisible}
                closeModal={() => setTermsConditionsVisible(false)}
            />

            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="shrink-0 grow-0 w-15 h-15 text-blue-kiwi">
                    <Subtract />
                </div>
                <h2 className="font-semibold text-xl mt-4 capitalize">
                    {userState.user.first_name} {userState.user.first_surname}
                </h2>
                <p className="text-sm">{userState.user.email}</p>
            </div>

            <div className="flex flex-col gap-10">
                {MENU_LIST.map((submenu, index) => (
                    <div className="flex flex-col gap-2" key={index}>
                        {submenu.map(link => (
                            <MenuLink
                                key={link.title}
                                title={link.title}
                                onClick={() => link.action.call(link)}
                                icon={link.icon}
                                description={link.description}
                            />
                        ))}
                    </div>
                ))}

                <div>
                    <div className="flex items-center cursor-pointer w-max gap-4">
                        <div className="bg-gray-100 w-10 h-10 rounded-full flex justify-center items-center">
                            <LogoutIcon className="w-6 text-gray-500-kiwi" />
                        </div>
                        <p className="font-medium" onClick={onLogout}>
                            Cerrar sesión
                        </p>
                    </div>
                </div>
            </div>

            <Menu selected="profile" />
        </div>
    );
}
