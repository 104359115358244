import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useContext,
    Fragment,
    useRef,
} from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import {useLocation, useNavigate} from 'react-router-dom';
import {ApprovedLoanContext} from 'context/ApprovedLoanContext';
import {UserContext} from 'context/UserContext';
import mixpanel from 'utils/mixpanel';
import {GAevent} from 'utils/GA';
import anime from 'animejs';
import Button from 'components/Button';
import moneyFormat from 'utils/moneyFormat';
import ProgressBar from 'components/ProgressBar';
import RejctedCreditLimitModal from 'components/Modals/RejectedCreditLimit';
import {CreditLimitContext} from 'context/CreditLimitContext';
import {Calendar2Icon, CurrencyDollarIcon} from 'assets/icons_v3/fonts';
import STATE_CONSTANTS from 'constants/states';
import {TiktokAPI} from 'api';

export default function ApprovedLoanAmount() {
    const navigate = useNavigate();
    const location = useLocation();

    const {state: userState} = useContext(UserContext);
    const {creditLimitErrorMessage} = useContext(CreditLimitContext);
    const {
        products: {personal_loan},
    } = userState;

    const {
        state: approvedState,
        saveState: saveApprovedState,
        initialState: initialApprovedState,
    } = useContext(ApprovedLoanContext);

    const [loading, setLoading] = useState(false);
    const [visibleRejected, setVisibleRejected] = useState(() => {
        return location?.state?.showRejectedModal;
    });
    const [showConfetti, setShowConfetti] = useState(false);
    const [stepAmount, setStepAmount] = useState(0);
    const [minLimit, setMinLimit] = useState(0);
    const [maxLimit, setMaxLimit] = useState(0);
    const [interestRate, setInterestRate] = useState(0);
    const [termMonths, setTermMonths] = useState(0);
    const [amount, setAmount] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [creditLimitError] = useState(creditLimitErrorMessage);

    const rangeFillRef = useRef();

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_slider_amount',
            dl_user_id: userState.user.id,
        });

        sendTikTokEvent();

        const {
            application: {credit_limit, interest_rate, term_months},
        } = personal_loan;

        setStepAmount(50);
        setMinLimit(150);
        setMaxLimit(credit_limit);
        setInterestRate(interest_rate);
        setTermMonths(term_months);
        creditLimitAnimation();

        if (approvedState.amount.completed) {
            onChangeAmount(approvedState.amount.value, interest_rate);
        } else {
            onChangeAmount(credit_limit, interest_rate);
        }
    }, []);

    useLayoutEffect(() => {
        onFillRange(amount);
    });

    const creditLimitAnimation = () => {
        const {state} = location;

        if (state?.oldLimit && state?.newLimit) {
            anime
                .timeline({})
                .add({
                    targets: '#amount',
                    innerText: [state.oldLimit, state.newLimit],
                    easing: 'easeInQuad',
                    duration: 500,
                    round: true,
                    complete: () => {
                        setShowConfetti(true);
                    },
                })
                .add({
                    targets: '#amount-message',
                    opacity: [0, 1],
                    duration: 500,
                });
        }
    };

    const onContinue = async () => {
        /**
         * NOTE: Varios de los usuarios que están
         * bloqueados tiene amount 1... no dejarlos pasar.
         */
        if (amount < minLimit) {
            return;
        }

        try {
            setLoading(true);

            /**
             * NOTE: Si cambia el monto, reiniciar todos los
             * valores para que todo se vuelva a calcular con
             * el nuevo monto...
             */
            if (approvedState.amount.value !== amount) {
                saveApprovedState({
                    ...initialApprovedState,
                    amount: {
                        termMonths,
                        value: amount,
                        completed: true,
                    },
                });

                dataLayer.push({
                    event: 'new_application',
                    eventCategory: 'loan_process',
                    eventAction: 'loan_process_pay_day',
                    dl_user_id: userState.user.id,
                    dl_loan_size: amount,
                });

                GAevent('user_action', 'loan_size', maxLimit);
                mixpanel.track('Select Loan Amount', {
                    'Selected Amount': amount,
                });
            }

            navigate('/approved-loan/repayment-details');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onBackward = () => {
        navigate('/home');
    };

    const {paymentFrequency, termsMonths, onCalculateInstallment} =
        STATE_CONSTANTS(userState.user.state).approved;

    const onChangeAmount = async (amount, interestRate) => {
        const terms = termsMonths(amount);
        const monthlyPayment = onCalculateInstallment(
            amount,
            interestRate,
            terms,
            paymentFrequency,
        );

        onFillRange(amount);

        setAmount(amount);
        setMonthlyPayment(monthlyPayment);
        setTermMonths(terms);
    };

    const onFillRange = amount => {
        const fillElement = rangeFillRef.current;

        /** 12 es la mitad del width del thumb del range input que es de 24px... */
        const width =
            ((amount - (minLimit - 12)) * 100) / (maxLimit - (minLimit - 12));

        fillElement.style.width = `${width}%`;

        return width;
    };

    const toggleRejectedModal = () => {
        setVisibleRejected(!visibleRejected);
    };

    const sendTikTokEvent = async () => {
        const data = {
            event_source: 'web',
            event_source_id: process.env.REACT_APP_TIKTOK_EVENT_SOURCE_ID,
            data: [
                {
                    event: 'ApprovedLoan',
                    event_time: Date.now(),
                    limited_data_use: true,
                    user: {
                        email: userState.user.email,
                        phone: userState.user.phone_number,
                    },
                    page: {
                        url: `${process.env.REACT_APP_FRONTEND_URL}approved-loan/amount`,
                    },
                },
            ],
        };

        await TiktokAPI.sendTikTokEvent(data);
    };

    return (
        <Fragment>
            <ProgressBar progress="1/10" onBackward={onBackward} />

            <RejctedCreditLimitModal
                messageType={creditLimitError}
                data={personal_loan.credit_limit}
                isVisible={visibleRejected}
                closeModal={toggleRejectedModal}
            />

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    Elige monto de préstamo
                </h1>
            </div>

            <div className="mb-auto">
                <div className="text-dark-kiwi font-semibold text-5xl flex items-center">
                    $<div id="amount">{moneyFormat(amount)}</div>
                    {showConfetti && (
                        <div>
                            <ConfettiExplosion
                                colors={[
                                    '#ffcc7f',
                                    '#ff9900',
                                    '#ffc166',
                                    '#ffd699',
                                ]}
                                particleCount={20}
                                particleSize={10}
                            />

                            <div
                                id="amount-message"
                                className="text-center text-xs bg-yellow-50-kiwi text-yellow-kiwi ml-4 p-2 px-4 rounded-lg">
                                ¡Nuevo límite de crédito!
                            </div>
                        </div>
                    )}
                </div>

                <div className="mt-8 mb-4 relative">
                    <input
                        defaultValue={amount}
                        min={minLimit}
                        max={maxLimit}
                        step={stepAmount}
                        type="range"
                        className="w-full h-3 bg-gray-200-kiwi rounded-lg appearance-none cursor-pointer"
                        onChange={$event =>
                            onChangeAmount(
                                Number($event.target.value),
                                interestRate,
                            )
                        }
                    />
                    <div
                        className={`absolute top-[calc(50%-6px)] left-0 h-3 rounded-lg bg-dark-kiwi w-[0%] pointer-events-none`}
                        ref={rangeFillRef}></div>
                </div>

                <div className="mb-8">
                    <div className="flex justify-between text-sm">
                        <label>Min ${moneyFormat(minLimit)}</label>
                        <label>Max ${moneyFormat(maxLimit)}</label>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center mr-4">
                            <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center">
                                <CurrencyDollarIcon className="w-6 text-regal-blue-kiwi" />
                            </div>
                            <div>Pagos</div>
                        </div>
                        <b className="capitalize">
                            ${moneyFormat(monthlyPayment)} {paymentFrequency}{' '}
                        </b>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center mr-4">
                            <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center">
                                <Calendar2Icon className="w-6 text-regal-blue-kiwi" />
                            </div>
                            <div>Cuotas</div>
                        </div>
                        <b className="capitalize">
                            {termsMonths(amount)} {paymentFrequency}
                        </b>
                    </div>
                </div>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                loading={loading}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    );
}
