import axios from 'axios';
import Cookies from 'js-cookie';
import {UAParser} from 'ua-parser-js';

const getLocationData = () => {
    const locationData = Cookies.get('dmp_location');
    return locationData ? JSON.parse(locationData) : null;
};
const trackEvent = async ({
    event_type,
    place = 'app',
    pathname = null,
    event_id = null,
    user_id = null,
}) => {
    if (process.env.REACT_APP_DMP_TRACKING === 'production') {
        return;
    }
    try {
        if (user_id && !Cookies.get('dmp_user_id')) {
            Cookies.set('dmp_user_id', user_id, {
                domain: 'kiwicredito.com',
                secure: true,
            });
        }
        const userdmp = Cookies.get('dmp_user_id');
        const sessionId = Cookies.get('dmp_session_id');
        const deviceId = Cookies.get('dmp_device_id');
        const eventDuration = Cookies.get('dmp_event_duration');
        const dateActivity = new Date().toISOString();
        const parser = new UAParser();
        const resultNavigator = parser.getResult();
        const utmInformation = getUTMParameters();

        if (!event_type) {
            return;
        }

        const location = getLocationData();

        const userCountry = location?.country?.name;
        const userState = location?.subdivisions[0]?.name;
        const userCity = location?.city?.name;
        const userZip = location?.postalCode;
        const userLatitude = location?.latitude;
        const userLongitude = location?.longitude;

        const logEvent = {
            user_information: {
                visitor_id: deviceId,
                user_id: userdmp ?? null,
                location: {
                    country: userCountry,
                    state: userState,
                    city: userCity,
                    zip: userZip,
                    latitude: userLatitude,
                    longitude: userLongitude,
                },
            },
            device_information: {
                device_id: deviceId,
                device_type: resultNavigator.device.type || 'desktop',
                os: resultNavigator.os.name,
                os_version: resultNavigator.os.version,
                app_version: resultNavigator.browser.version,
                browser: resultNavigator.browser.name,
            },
            event_information: {
                event_type: event_type,
                event_id: event_id,
                session_id: sessionId,
                event_timestamp: dateActivity,
                event_duration: parseInt(eventDuration),
                reference_page: place,
                event_url: pathname,
                utm_parameters: {
                    utm_source: utmInformation.utm_source,
                    utm_medium: utmInformation.utm_medium,
                    utm_campaign: utmInformation.utm_campaign,
                    utm_term: utmInformation.utm_term,
                    utm_content: utmInformation.utm_content,
                },
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SQS_URL}/send-message`,
                {
                    ...logEvent,
                },
            );

            console.log('Event tracked:', response.data);
        } catch (error) {
            console.log('Error tracking event:', error);
        }

        Cookies.set('dmp_last_activity_seen', dateActivity, {
            domain: 'kiwicredito.com',
            secure: true,
        });
    } catch (error) {
        console.error('Error tracking event:', error);
    }
};

const getUTMParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const utmParams = {
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
        utm_term: urlParams.get('utm_term'),
        utm_content: urlParams.get('utm_content'),
    };

    return utmParams;
};

export {trackEvent};
