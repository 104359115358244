import {LoanAPI} from 'api';
import BackwardButton from 'components/Button/Backward';
import LoadingModal from 'components/Modals/Loading';
import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import ContractTemplate from 'templates/Contract';

export default function DocumentsContract() {
    const location = useLocation();
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(true);
    const [fileUrl, setFileUrl] = useState('');

    const {document_id, file_original_name, file_name} = location?.state ?? {};

    useEffect(() => {
        if (!location.state?.document_id) {
            navigate('/documents/products');
        }

        getLoanDocument();
    }, []);

    const getLoanDocument = async () => {
        try {
            const {data} = await LoanAPI.getLoanDocument({
                document_id,
                file_name: file_original_name,
            });
            if (data) {
                const reader = new window.FileReader();

                reader.readAsDataURL(data);
                reader.onload = () => {
                    setFileUrl(reader.result);
                };
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }
    };

    const onBackward = () => {
        navigate(-1);
    };

    const onOpenContract = async () => {
        const response = await fetch(fileUrl);
        const fileBlob = await response.blob();
        const alink = document.createElement('a');

        alink.href = window.URL.createObjectURL(fileBlob);
        alink.download = file_original_name + '.pdf';
        alink.click();
    };

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />
            {fileUrl && !showLoader && (
                <ContractTemplate
                    signed={true}
                    title={file_name}
                    fileUrl={fileUrl}
                    buttonTitle="Descargar contrato"
                    signContract={onOpenContract}
                />
            )}
        </Fragment>
    );
}
