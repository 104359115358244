import * as Yup from 'yup';
import React, {Fragment, useContext, useRef, useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';

import {useLocation, useNavigate} from 'react-router-dom';
import PatternFormat from 'react-number-format';
import BackwardButton from 'components/Button/Backward';
import PrimaryButton from 'components/Button/Primary';
import ConfirmCombineAccountsModal from 'components/Modals/ConfirmCombineAccounts';
import {CombineAccountsContext} from 'context/CombineAccounts';
import {UsersAPI} from 'api';
import LoadingModal from 'components/Modals/Loading';

export default function CombineAccountsVerification() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(false);
    const {state: combineState, setState: setCombineState} = useContext(
        CombineAccountsContext,
    );
    const form = useRef();
    const ConfirmCombineAccountsRef = useRef();

    const onBackward = () => {
        if (location.state?.backwardRoute) {
            return navigate(location?.state?.backwardRoute);
        }

        navigate('/home');
    };

    const onConfirmCombine = async () => {
        try {
            setShowLoader(true);

            const phoneNumber = form.current.values.phone;

            await UsersAPI.confirmMergeAccount({
                phoneNumber,
                ssn: combineState.ssn,
            });

            setCombineState({
                ...combineState,
                previousPhoneNumber: phoneNumber,
            });

            navigate('/combine-accounts/sent');
        } catch (error) {
            ConfirmCombineAccountsRef.current.closeModal();
            form.current.setFieldError(
                'phone',
                'Problemas al guardar tu SSN, por favor contacta a support@kiwicredito.com',
            );
        } finally {
            setShowLoader(false);
        }
    };

    const onSubmit = async () => {
        ConfirmCombineAccountsRef.current.openModal();
    };

    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .required('Este campo es requerido')
            .test(
                'min-length',
                'El formato del número debe ser de 10 dígitos',
                (phone = '') => {
                    const plainPhone = phone?.replace(/[^\d]/g, '');

                    if (plainPhone.length === 10) {
                        return true;
                    }
                },
            ),
    });

    const initialValues = {
        phone: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={form}>
            {({errors, touched, isValid, dirty}) => (
                <Fragment>
                    <LoadingModal isVisible={showLoader} />
                    <ConfirmCombineAccountsModal
                        ref={ConfirmCombineAccountsRef}
                        onConfirmCombine={onConfirmCombine}
                    />
                    <BackwardButton onBackward={onBackward} />
                    <Form className="flex flex-col grow">
                        <div className="mb-10">
                            <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                                Verifica tu información
                            </h1>
                            <p className="mb-10">
                                Para continuar, por favor verifica tu
                                información. Si los datos coinciden, recibirás
                                un correo a{' '}
                                <span className="font-semibold">
                                    {combineState.email}
                                </span>{' '}
                                para actualizar la información de tu cuenta:
                            </p>

                            <div>
                                <label
                                    htmlFor="phone"
                                    className="inline-block text-sm mb-2">
                                    Número de celular anterior
                                </label>
                                <div className="relative">
                                    <div
                                        className={`absolute top-1/2 left-4 -translate-y-1/2 ${
                                            errors.phone && touched.phone
                                                ? 'text-red-kiwi'
                                                : ''
                                        }`}>
                                        +1
                                    </div>
                                    <Field name="phone" id="phone">
                                        {({field, ...props}) => (
                                            <PatternFormat
                                                {...field}
                                                {...props}
                                                autoComplete="off"
                                                className={`w-full rounded-lg py-2 px-4 pl-10 ${
                                                    errors.phone &&
                                                    touched.phone
                                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                                format="(###) ### ####"
                                                placeholder="(000) 000 0000"
                                                mask=""
                                            />
                                        )}
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name="phone"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <PrimaryButton
                            disabled={!isValid || !dirty}
                            type="submit"
                            className="mt-auto">
                            Continuar
                        </PrimaryButton>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
