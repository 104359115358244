import React, {useState, useContext, useRef, Fragment} from 'react';

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';
import {AuthContext} from 'context/AuthContext';

import EmailOnboardingSVG from 'assets/onboarding_v3/email.svg';
import Button from 'components/Button';
import BackwardButton from 'components/Button/Backward';
import Advisement from 'components/Advisement';
import {UserContext} from 'context/UserContext';

export default function ChangePhoneForm() {
    const navigate = useNavigate();
    const form = useRef();

    const {sendEmailChangePhone, state: authState} = useContext(AuthContext);
    const {state: userState} = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const onSendEmail = async ({email, ssn}) => {
        try {
            setLoading(true);
            await sendEmailChangePhone(email, ssn);
            navigate('/change-phone/sent-email');
        } catch (error) {
            const {messages} = error.response.data;

            if (messages === 'Email not found') {
                const message = customErrorMessage(
                    'Este correo electrónico no existe, haz click aquí para ponerte en contacto con nosotros',
                );
                form.current.setFieldError('email', message);
            }

            if (messages === 'User does not match') {
                const message = customErrorMessage(
                    'Hay un problema con tu SSN, haz click aquí para ponerte en contacto con nosotros',
                );
                form.current.setFieldError('ssn', message);
            }
        } finally {
            setLoading(false);
        }
    };

    const onBackward = () => {
        if (authState.logged) {
            return navigate('/personal-information');
        }

        navigate('/login');
    };

    const customErrorMessage = message => {
        return (
            <Fragment>
                {message + ' '}
                <a
                    href="mailto:support@kiwicredito.com;?subject=Solicitud cambio de número teléfono"
                    className="font-bold">
                    support@kiwicredito.com
                </a>
            </Fragment>
        );
    };

    const initialValues = {
        email: userState.user?.email ?? '',
        ssn: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Este campo es requerido')
            .email('Correo electrónico inválido'),
        ssn: Yup.string()
            .required('Este campo es requerido')
            .matches(/^[0-9]+$/, 'Ingresa solo números')
            .min(4, 'Ingresa solo los ultimos 4 dígitos')
            .max(4, 'Ingresa solo los ultimos 4 dígitos'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSendEmail}
            validationSchema={validationSchema}
            innerRef={form}>
            {({errors, touched}) => (
                <Form className="flex flex-col grow" autoComplete="off">
                    <BackwardButton onBackward={onBackward} />

                    <div className="flex flex-col text-center">
                        <img
                            className="mx-auto mb-8"
                            src={EmailOnboardingSVG}
                        />
                        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                            Ingresa tu correo electrónico registrado
                        </h1>
                        <p>
                            Te enviaremos un enlace para cambiar tu número de
                            celular
                        </p>
                    </div>

                    <div className="flex flex-col gap-6 my-10">
                        <div>
                            <label
                                htmlFor="email"
                                className="inline-block text-sm mb-2">
                                Correo electrónico
                            </label>
                            <Field
                                id="email"
                                type="email"
                                placeholder="example@gmail.com"
                                name="email"
                                disabled={loading}
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.email && touched.email
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="email"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="ssn"
                                className="inline-block text-sm mb-2">
                                Últimos 4 dígitos de tu SSN
                            </label>
                            <Field
                                id="ssn"
                                type="text"
                                placeholder="0000"
                                name="ssn"
                                maxLength="4"
                                disabled={loading}
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.ssn && touched.ssn
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="ssn"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>
                    </div>

                    <div className="mt-auto">
                        <Advisement className="mb-6" icon="email">
                            Si no puedes validar tu correo electrónico, contacta
                            a nuestro equipo de soporte enviándonos un correo a{' '}
                            <span className="font-semibold">
                                support@kiwicredito.com
                            </span>
                        </Advisement>

                        <Button
                            id="create_account_success-gtm"
                            className="bg-blue-kiwi w-full text-white rounded-xl p-3"
                            type="submit"
                            loading={loading}>
                            Enviar correo
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
