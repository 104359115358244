import React, {Fragment, useContext, useEffect, useState} from 'react';

import SuccessSVG from 'assets/onboarding_v3/email_message.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import BackwardButton from 'components/Button/Backward';
import PrimaryButton from 'components/Button/Primary';
import LoadingModal from 'components/Modals/Loading';
import {CombineAccountsContext} from 'context/CombineAccounts';
import {UsersAPI} from 'api';

export default function CombineAccountsSent() {
    const navigate = useNavigate();
    const location = useLocation();

    const [showLoader, setShowLoader] = useState(false);
    const {state: combineState} = useContext(CombineAccountsContext);

    const RESEND_EMAIL_TIME = 60;
    const [resendCount, setResendCount] = useState(RESEND_EMAIL_TIME);
    const [resendCountStart, setResendCountStart] = useState(false);

    useEffect(() => {
        if (!combineState.previousPhoneNumber || !combineState.ssn) {
            return onBackward();
        }

        onStartCounting();
    }, []);

    const onStartCounting = async () => {
        setResendCountStart(true);

        let timeLeft = resendCount;
        let resendInterval = setInterval(() => {
            timeLeft = timeLeft - 1;

            if (timeLeft <= 0) {
                clearInterval(resendInterval);
                setResendCount(RESEND_EMAIL_TIME);
                setResendCountStart(false);
            } else {
                setResendCount(timeLeft);
            }
        }, 1000);
    };

    const onResendEmail = async () => {
        try {
            setShowLoader(true);

            await UsersAPI.confirmMergeAccount({
                phoneNumber: combineState.previousPhoneNumber,
                ssn: combineState.ssn,
            });

            onStartCounting();
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }
    };

    const onBackward = () => {
        navigate('/combine-accounts');
    };

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-10" src={SuccessSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    ¡Revisa tu correo electrónico!
                </h1>
                <p>
                    Te enviamos un enlace de verificación para actualizar tu
                    cuenta. Si no lo recibes en unos minutos, podrás solicitar
                    un nuevo envío en{' '}
                    <span className="text-blue-kiwi">
                        {resendCountStart === false ? (
                            <span onClick={onResendEmail}>reenviar ahora</span>
                        ) : (
                            `${resendCount}s`
                        )}
                    </span>
                </p>
            </div>

            <p className="text-xs text-gray-500-kiwi mb-10">
                Si no recibes el correo, puedes comunicarte con nosotros al{' '}
                <strong>support@kiwicredito.com</strong>
            </p>

            <PrimaryButton disabled={resendCountStart} onClick={onResendEmail}>
                Enviar nuevamente
            </PrimaryButton>
        </Fragment>
    );
}
