import React, {useState, useEffect, Fragment, useContext, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';
import mixpanel from 'utils/mixpanel';

import SSNTemplate from 'templates/SSN';
import ProgressBar from 'components/ProgressBar';
import Advisement from 'components/Advisement';
import ConsentTermsModal from 'components/Modals/ConsentTerms';
import PatriotaTermsModal from 'components/Modals/PatriotaTerms';
import ECBSVTermsModal from 'components/Modals/ECBSVTerms';
import CreditTermsModal from 'components/Modals/CreditTerms';
import {UserContext} from 'context/UserContext';
import {STATES_AVAILABLES} from 'constants';
import {UsersAPI} from 'api';

export default function RequestLoanSSN() {
    const navigate = useNavigate();
    const ECBSVTermsRef = useRef();

    const {state: userState} = useContext(UserContext);
    const {state: requestState, saveState: saveRequestState} =
        useContext(RequestLoanContext);

    const [consentTermsVisible, setConsentTermsVisible] = useState(false);
    const [patriotaTermsVisible, setPatriotaTermsVisible] = useState(false);
    const [creditTermsVisible, setCreditTermsVisible] = useState(false);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_social_number',
            dl_user_id: userState.user.id,
            dl_city: userState.user.city,
            dl_state: userState.user.state,
        });

        if (requestState.address.completed === false) {
            return navigate('/request-loan/address');
        }
    }, []);

    const onContinue = async () => {
        if (
            userState.user.state === STATES_AVAILABLES.florida &&
            requestState.veritec?.completed === false
        ) {
            return navigate('/request-loan/veritec');
        }

        await saveCreditReport();

        mixpanel.track('SSN Verification');
        navigate('/request-loan/income');
    };

    const saveCreditReport = async () => {
        if (requestState.ssn.completed === false) {
            await UsersAPI.creditReport({
                source: 'loan_ask'
            });

            await saveRequestState({
                ...requestState,
                ssn: {
                    completed: true,
                },
            });
        }
    };

    const onBackward = () => {
        navigate('/request-loan/address');
    };

    const WarningSlot = (
        <Advisement className="mt-10" icon="shield_check">
            La verificación de tu aprobación es gratuita y el 'soft-pull'{' '}
            <strong>no afectará</strong> tu puntaje de crédito.
        </Advisement>
    );

    const TermsSlot = (
        <p>
            Acepto que he leído y estoy de acuerdo con la{' '}
            <a
                className="text-blue-kiwi underline cursor-pointer"
                href="https://www.kiwicredito.com/privacy-policy"
                target="_blank">
                Política de Privacidad
            </a>
            , los{' '}
            <a
                className="text-blue-kiwi underline cursor-pointer"
                href="https://www.kiwicredito.com/terms-use"
                target="_blank">
                Términos de Uso
            </a>
            , el{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setConsentTermsVisible(true)}>
                Consentimiento de Verificación de Identidad
            </span>
            , el{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => ECBSVTermsRef.current.openModal()}>
                Consentimiento de Verificación eCBSV
            </span>
            , la{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setCreditTermsVisible(true)}>
                Autorización de Perfil de Crédito
            </span>
            , y autorizo a Kiwi a verificar mis ingresos. Además, la
            verificación de identidad garantiza el cumplimiento de los
            requisitos de la{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setPatriotaTermsVisible(true)}>
                Ley PATRIOTA de los EE.UU.
            </span>
        </p>
    );

    return (
        <Fragment>
            <ProgressBar progress="2/12" onBackward={onBackward} />

            <SSNTemplate
                context={RequestLoanContext}
                onContinue={onContinue}
                warningSlot={WarningSlot}
                termsSlot={TermsSlot}
                needCustomReport={true}
            />
            <ConsentTermsModal
                isVisible={consentTermsVisible}
                closeModal={() => setConsentTermsVisible(false)}
            />
            <CreditTermsModal
                isVisible={creditTermsVisible}
                closeModal={() => setCreditTermsVisible(false)}
            />
            <ECBSVTermsModal ref={ECBSVTermsRef} />
            <PatriotaTermsModal
                isVisible={patriotaTermsVisible}
                closeModal={() => setPatriotaTermsVisible(false)}
            />
        </Fragment>
    );
}
