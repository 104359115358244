import dayjs from 'dayjs';
import React, {useState, useEffect, useContext, Fragment, useRef} from 'react';
import {UserContext} from 'context/UserContext';

import Button from 'components/Button';
import LoadingModal from 'components/Modals/Loading';
import Advisement from 'components/Advisement';
import APRModal from 'components/Modals/APR';
import Emitter from 'utils/emitter';
import {RepaymentTermsAPI} from 'api';
import {ApprovedLoanContext} from 'context/ApprovedLoanContext';
import RepaymentTermsTemplate from 'templates/RepaymentTerms';

export default function RepaymentTerms({onSuccess}) {
    const {state: userState} = useContext(UserContext);
    const {state: approvedState, saveState: saveApprovedState} =
        useContext(ApprovedLoanContext);

    const APRModalRef = useRef();

    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        onCreateTempLoan();
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_payment_details',
            dl_user_id: userState.user.id,
        });
    }, []);

    const onCreateTempLoan = async () => {
        try {
            setLoading(true);
            setShowLoader(true);

            if (approvedState.repaymentTerms.tempLoanId === '') {
                const {
                    data: {loan: repaymentTerms},
                } = await RepaymentTermsAPI.createTempLoan({
                    user_id: userState.user.id,
                    amount: approvedState.amount.value,
                    termMonths: approvedState.amount.termMonths,
                    paymentDay: approvedState.paymentDay.value,
                });

                saveApprovedState({
                    ...approvedState,
                    repaymentTerms: {
                        ...repaymentTerms,
                        completed: false,
                    },
                });
            }
        } catch (error) {
            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Error al crear tu préstamo',
                message:
                    'Parece que tuvimos problemas al crear tu préstamo. Por favor inténtalo nuevamente, si el problema persiste contacta a support@kiwicredito.com',
            });
        } finally {
            setLoading(false);
            setShowLoader(false);
        }
    };

    const onContinue = async () => {
        try {
            setLoading(true);

            await RepaymentTermsAPI.createLoanproCustomer(userState.user.id);

            onSuccess();
        } catch (error) {
            const {response} = error;

            if (response.data?.error.includes('duplicate found')) {
                onSuccess();
            }
        } finally {
            setLoading(false);
        }
    };

    const {repaymentTerms, amount} = approvedState;

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <APRModal ref={APRModalRef} />

            <RepaymentTermsTemplate
                loading={showLoader}
                loan={{
                    id: repaymentTerms.tempLoanId,
                    totalLoanAmount: amount.value,
                    monthlyPayment: repaymentTerms.monthlyPayment,
                    totalAmountIncludingFee:
                        repaymentTerms.totalAmountIncludingFee,
                    firstPaymentDate: dayjs.unix(
                        repaymentTerms.firstPaymentDate.replace(
                            /^.*?(\d+).*/,
                            '$1',
                        ),
                    ),
                    interestRate: repaymentTerms.interestRate,
                    apr: repaymentTerms.APR,
                    term: repaymentTerms.termMonths,
                }}
            />

            <Advisement icon="bulb_money" className="mt-auto">
                <strong>Puedes saldar tu préstamo antes</strong> sin costos
                extra, pagando solo los intereses acumulados hasta la fecha.
            </Advisement>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                loading={loading}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    );
}
