import React, {useEffect, Fragment} from 'react';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';
import {initGA} from './utils/GA';
import Notification from './components/Modals/Notification';
import Routes from './routes';
import {v4 as uuidv4} from 'uuid';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';

const App = () => {
    const DOMAIN = '.kiwicredito.com';
    zE('messenger', 'hide');

    const {getData: getDataFingerprint} = useVisitorData(
        {extendedResult: true},
        {immediate: true},
    );

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const referral_code = queryParameters.get('referral_code');
        const kw_source_url = Cookies.get('kw_source_url');
        const kw_referral_code = Cookies.get('kw_referral_code');

        if (!kw_source_url) {
            Cookies.set('kw_source_url', window.location.href);
        }

        if (!kw_referral_code && referral_code) {
            Cookies.set('kw_referral_code', referral_code);
        }
        LogRocket.init('fqaolv/kiwi');
        initGA();
        window.addEventListener('resize', setRootWidth);
        setRootWidth();
        if (process.env.REACT_APP_DMP_TRACKING === 'development') {
            setLocationVisitor();
            setSessionId();
            setDeviceId();
        }
    }, []);

    const storeLocationData = locationData => {
        const inEightHours = new Date(
            new Date().getTime() + 8 * 60 * 60 * 1000,
        );
        Cookies.set('dmp_location', JSON.stringify(locationData), {
            domain: DOMAIN,
            secure: true,
            expires: inEightHours,
        });
    };

    const getLocationData = () => {
        const locationData = Cookies.get('dmp_location');
        return locationData ? JSON.parse(locationData) : null;
    };

    const setLocationVisitor = async () => {
        if (getLocationData()) return;
        const response = await getDataFingerprint();
        storeLocationData(response.ipLocation);
    };

    const setDeviceId = async () => {
        const cookieDeviceId = Cookies.get('dmp_device_id');
        if (!cookieDeviceId) {
            console.log('setting new device id', uuidv4());
            Cookies.set('dmp_device_id', uuidv4(), {
                domain: DOMAIN,
                secure: true,
            });
        }
    };

    const setSessionId = async () => {
        const dmp_session_id = Cookies.get('dmp_session_id');
        const lastActivitySeenDMP = Cookies.get('dmp_last_activity_seen');
        handleLastActivity(lastActivitySeenDMP, dmp_session_id);
    };

    const handleLastActivity = (lastActivitySeenDMP, dmp_session_id) => {
        const lastActivity = new Date(lastActivitySeenDMP);
        const now = new Date();
        const diff = now - lastActivity;

        Cookies.set('dmp_event_duration', diff);

        if (diff > 3600000) {
            setNewSessionId();
        }
        if (!dmp_session_id) {
            setNewSessionId();
        }
    };

    const setNewSessionId = () => {
        const inOneHour = new Date(new Date().getTime() + 1 * 60 * 60 * 1000);
        Cookies.set('dmp_session_id', uuidv4(), {
            domain: DOMAIN,
            secure: true,
            expires: inOneHour,
        });
    };

    const setRootWidth = () => {
        const root = document.getElementById('root');

        if (window.innerWidth > 480) {
            root.classList.add('desktop');
        } else {
            root.classList.remove('desktop');
        }
    };

    return (
        <Fragment>
            <Notification />
            <Routes />
        </Fragment>
    );
};

export default App;
