import React, {useState, createContext, useEffect} from 'react';
export const CombineAccountsContext = createContext();

export const CombineAccountsProvider = ({children}) => {
    const initialState = {
        ssn: '',
        last4PhoneNumber: '',
        email: '',
        previousPhoneNumber: '',
    };

    const [state, setState] = useState(() => {
        const state = JSON.parse(localStorage.getItem('combineContext'));

        if (state === null) {
            return initialState;
        }

        return state;
    });

    useEffect(() => {
        localStorage.setItem('combineContext', JSON.stringify(state));
    }, [state]);

    const resetState = () => {
        setState(initialState);
    };

    const providerValues = {
        initialState,
        state,
        setState,
        resetState,
    };

    return (
        <CombineAccountsContext.Provider value={providerValues}>
            {children}
        </CombineAccountsContext.Provider>
    );
};
