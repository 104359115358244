import mixpanel from 'utils/mixpanel'
import React, { Fragment, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ChartLineSVG from 'assets/icons_v3/chart_line.svg'
import TachometerSVG from 'assets/icons_v3/tachometer.svg'
import { UserContext } from 'context/UserContext'
import {
    STATES_AVAILABLES,
    CREDIT_BUILDER_STATUS,
    PERSONAL_LOAN_STATUS
} from 'constants'

export default function HomeProducts({ className }) {
    const navigate = useNavigate()

    const { state: userState } = useContext(UserContext)
    const {
        user,
        products: {
            personal_loan: { status: personalLoanStatus },
            credit_builder: { status: creditBuilderStatus }
        }
    } = userState

    const isPersonalLoanActive =
        personalLoanStatus === PERSONAL_LOAN_STATUS.current

    const isCreditBuilderActive =
        creditBuilderStatus === CREDIT_BUILDER_STATUS.current

    const [showCreditMonitoring] = useState(() => {
        if (
            window.location.origin.includes('ios') ||
            window.location.origin.includes('android')
        ) {
            return false
        }

        return true
    })

    const onCreditBuilderRedirect = () => {
        mixpanel.track('Credit Builder')
        navigate('/credit-builder')
    }

    const onCreditMonitoringRedirect = async () => {
        mixpanel.track('Credit Monitoring')
        navigate('/credit-monitoring')
    }

    const onProducts = () => {
        navigate('/products')
    }

    const showCreditBuilderCard = isCreditBuilderActive === false

    const showCreditMonitoringCard =
        isPersonalLoanActive === false &&
        isCreditBuilderActive === false &&
        showCreditMonitoring === true

    return (
        <Fragment>
            {(showCreditBuilderCard || showCreditMonitoringCard) && (
                <div className={`${className}`}>
                    <div className="flex items-center justify-between mb-4">
                        <div className="font-semibold text-sm">
                            Otros productos
                        </div>
                        <div
                            className="text-blue-kiwi text-xs cursor-pointer"
                            onClick={onProducts}>
                            Ver todos
                        </div>
                    </div>

                    <div className="flex gap-4">
                        {showCreditBuilderCard && (
                            <div
                                className="flex-auto p-4 rounded-2xl border border-slate-200-kiwi cursor-pointer"
                                onClick={onCreditBuilderRedirect}>
                                <img src={ChartLineSVG} className="mb-4" />
                                <div className="font-semibold text-sm mb-2">
                                    Credit Builder
                                </div>
                                <div className="text-xs">
                                    Construye tu historial de crédito por tan
                                    solo $5/mes
                                </div>
                            </div>
                        )}

                        {showCreditMonitoringCard && (
                            <div
                                className="flex-auto p-4 rounded-2xl border border-slate-200-kiwi cursor-pointer"
                                onClick={onCreditMonitoringRedirect}>
                                <img src={TachometerSVG} className="mb-4" />
                                <div className="font-semibold text-sm mb-2">
                                    Credit Monitoring
                                </div>
                                <div className="text-xs">
                                    Protégete contra el fraude y verifica tu
                                    reporte de crédito
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    )
}
