import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import RatingModal from 'components/Modals/Rating';
import RatingThankModal from 'components/Modals/RatingThank';
import CuponCard from 'components/CuponCard';
import SuccessMoneySVG from 'assets/onboarding_v3/success_money.svg';
import {UserContext} from 'context/UserContext';
import {TiktokAPI} from 'api';

export default function MoneysOnTheWay() {
    const navigate = useNavigate();
    const [hasRating, setHasRating] = useState(false);
    const [showRating, setShowRating] = useState(false);
    const [showRatingThank, setShowRatingThank] = useState(false);
    const [scoreSelected, setScoreSelected] = useState(null);
    const {state: userState, getLoanFundingDate} = useContext(UserContext);

    const closeRatingModal = () => {
        setShowRating(!showRating);
    };

    const closeRatingThankModal = () => {
        setShowRatingThank(!showRatingThank);
    };

    const onContinue = () => {
        if (
            (!hasRating && window.location.origin.includes('ios')) ||
            window.location.origin.includes('android')
        ) {
            setHasRating(true);
            return setShowRating(true);
        }

        navigate('/home');
    };

    useEffect(() => {
        sendTikTokEvent();
        _cio.page('money_on_the_way', {extraDataObject: 'moreData'});
    }, []);

    useEffect(() => {
        if (scoreSelected !== null) {
            setShowRating(false);

            if (scoreSelected < 5) return setShowRatingThank(true);

            if (scoreSelected === 5) {
                if (window.location.origin.includes('ios'))
                    window.open(
                        'itms-apps://itunes.apple.com/app/id1488345108?action=write-review',
                    );

                if (window.location.origin.includes('android'))
                    window.open(
                        'https://play.google.com/store/apps/details?id=com.kiwwi.android&showAllReviews=true',
                    );
            }
        }
    }, [scoreSelected]);

    const sendTikTokEvent = async () => {
        const data = {
            event_source: 'web',
            event_source_id: process.env.REACT_APP_TIKTOK_EVENT_SOURCE_ID,
            data: [
                {
                    event: 'FundingInProcess',
                    event_time: Date.now(),
                    limited_data_use: true,
                    user: {
                        email: userState.user.email,
                        phone: userState.user.phone_number,
                    },
                    page: {
                        url: `${process.env.REACT_APP_FRONTEND_URL}/moneys-on-the-way`,
                    },
                },
            ],
        };

        await TiktokAPI.sendTikTokEvent(data);
    };

    return (
        <>
            <div className="w-full min-h-full flex flex-col px-6 py-10">
                <div className="flex flex-col justify-center min-h-full text-center m-auto">
                    <img className="mx-auto mb-8" src={SuccessMoneySVG} />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        Tu dinero está en camino
                    </h1>
                    <p>
                        Recibirás el contrato en tu correo electrónico. Tu
                        transferencia está siendo procesada y se espera que los
                        fondos lleguen el día{' '}
                        <strong>{getLoanFundingDate()}</strong>*
                    </p>
                </div>

                <div className="mt-10">
                    <CuponCard className="mb-6" />
                    <p className="text-xs text-gray-500-kiwi mb-10">
                        *En caso de no poder validar alguna información sobre tu
                        solicitud, nuestro equipo de soporte se comunicará con
                        usted
                    </p>
                    <button
                        className="bg-blue-kiwi text-white rounded-xl p-3 w-full"
                        onClick={onContinue}>
                        Continuar
                    </button>
                </div>
            </div>

            <RatingModal
                isVisible={showRating}
                closeModal={closeRatingModal}
                ratingStatus={setScoreSelected}
            />
            <RatingThankModal
                isVisible={showRatingThank}
                closeModal={closeRatingThankModal}
            />
        </>
    );
}
