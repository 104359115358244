import React, {useContext, useState, useEffect, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from 'context/AuthContext';
import {UserContext} from 'context/UserContext';
import {setBearerToken} from 'utils/http';
import {setBearerTokenFirebase} from 'utils/httpFirebase';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';

import SuccessSVG from 'assets/onboarding_v3/success.svg';
import Button from 'components/Button';
import {TiktokAPI, UsersAPI} from 'api';

export default function CreatedAccountSuccess() {
    const navigate = useNavigate();

    const {setState: setAuthState} = useContext(AuthContext);
    const {
        setState: setUserState,
        state: userState,
        getUser,
    } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const {getData: getDataFingerprint} = useVisitorData(
        {extendedResult: true},
        {immediate: true},
    );

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'register_process',
            eventAction: 'register_process_thank_you',
            dl_user_id: userState.user.id,
            dl_birthday: userState.user.birthdate,
            dl_gender: userState.user.title,
        });
        sendTikTokEvent();
        if (userState.user.id === undefined) {
            return navigate('/create-account');
        }
    }, []);

    const getDataUser = async () => {
        const {
            data: {user},
        } = await getUser(userState.user.id);

        setUserState(prev => ({
            ...prev,
            user,
        }));
    };

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'create_account_success',
                },
            });

            await UsersAPI.fingerprintEvaluate();
            await getDataUser();
        } catch (error) {
            console.error(error);
        }
    };

    const onContinue = async () => {
        if (loading === true) {
            return;
        }

        try {
            setLoading(true);
            const accessToken = userState.user.access_token;
            const refreshToken = userState.user.refresh_token;

            setBearerToken(accessToken);
            setBearerTokenFirebase(accessToken);

            getDataUser();

            setAuthState(prev => ({
                ...prev,
                accessTokenApp: accessToken,
                accessTokenRefresh: refreshToken,
                logged: true,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            fingerprintSetData();
            setLoading(false);
        }
    };

    const sendTikTokEvent = async () => {
        const data = {
            event_source: 'web',
            event_source_id: process.env.REACT_APP_TIKTOK_EVENT_SOURCE_ID,
            data: [
                {
                    event: 'CreatedAccount',
                    event_time: Date.now(),
                    limited_data_use: true,
                    user: {
                        email: userState.user.email,
                        phone: userState.user.phone_number,
                    },
                    page: {
                        url: `${process.env.REACT_APP_FRONTEND_URL}/create-account/success`,
                    },
                },
            ],
        };

        await TiktokAPI.sendTikTokEvent(data);
    };

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Bienvenido a Kiwi!
                </h1>
                <p>
                    Tu cuenta ya está activa y lista para usar. Disfruta de
                    todas las funciones y beneficios que nuestra app tiene para
                    ofrecerte.
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                onClick={onContinue}>
                Explorar
            </Button>
        </Fragment>
    );
}
